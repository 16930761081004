<template>
    <div>
        <v-container fluid class="pa-6">
            <DatatableV2 :displayTitle="false" :flat="false" @openOperationStepper="openOperationStepper" :url="apiUrl"/>
            <OperationStepperDialog @closeModal="closeStepperModal" :displayOperationStepper="displayOperationStepper" :operationId="operationId"/>
        </v-container>
    </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
import OperationStepperDialog from '@/modules/operations/operationsComponents/OperationStepperDialog'
export default {
    name:'OperationsTypesList',
    components: {
        DatatableV2,
        OperationStepperDialog
    },
    data() {
        return {
            apiUrl:"/operationtype/list",
            displayOperationStepper: false,
            operationId: null
        }
    },
    methods: {
        openOperationStepper(id){
            this.operationId = id
            this.displayOperationStepper = true
        },
        closeStepperModal(){
            this.displayOperationStepper = false
        }
    },
}
</script>