<template>

    <v-card class="elementStat py-6 px-2" depressed rounded="lg" :outlined="campaignPage" @mouseover="item.hover = true" :class="classProp" @mouseout="item.hover = false">
        <v-icon large :color="classProp == 'fixed' ? 'white' : (item.hover ? '#ffffff' : 'primary')">{{item.icon}}</v-icon>
        <h3 class="mt-4">{{item.value}}<span v-if="item.total">/{{item.total}}</span></h3>
        <span class="label mt-3" style="width:100%; display:flex; align-items:center; justify-content:center">
            {{$t(item.label)}}
            <v-tooltip right v-if="tooltip" content-class="white--tooltip" max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="info" class="ml-2" v-bind="attrs" v-on="on">$circleinfo_d</v-icon>
                </template>
                <span v-html="$t(tooltip)">
                </span>    
            </v-tooltip>
        </span>
    </v-card>

</template>


<script>
    export default {
        name:"NumberStatCardComponent",
        props:['item', 'classProp', 'campaignPage', 'tooltip']
    }
</script>

<style lang="scss">

    .elementStat {
        width:100%;
        height: 100%;
        &.forceHover {
            &.hover{
                &:hover{
                    background-color: var(--v-pink-base);
                    cursor:pointer;
                    color:#ffffff;
                }
            }
        }
        &.fixed {
            background-color: var(--v-primary-base);
            color:#ffffff;
        }

        text-align: center;
        h3 {
            color:inherit !important;
            font-weight: 800;
            font-size:30px;
            @media (-webkit-device-pixel-ratio: 1.25) {
                font-size: 30px;
                margin-top:10px;
            }

            span{
                font-size:15px;
                font-weight: 400;
            }
        }
        .label {
            display: block;
            font-size:13px;
        }
    }

</style>
