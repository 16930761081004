<template>
    <DatatableV2 :displayTitle="false" v-if="loadData" :flat="true" :url="apiUrl"/>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
export default {
    name:"attributionRdvStepComponent",
    components: {DatatableV2},
    props:['operationId', 'loadData'],
    data() {
        return {
            //apiUrl:"/operation/getRdvAttribution",
            //apiUrl:"/entity/list",
        }
    },
    computed: {
        apiUrl(){
            return "/operation/"+this.operationId+"/getTeamPlannings"
        }
    },
}
</script>
<style lang="scss">

</style>
