<template>
    <div class="mt-6 coachStep">
        <v-row>
            <v-col cols="4" v-for="(rps, index) in groupedRp" :key="'group_'+index+'_'+keySelector">
                <h4>
                    <span v-for="(rp, indexRp) in rps['rps']" :key="'rps_'+indexRp">
                        <span class="rpName">{{ rp.config.name }}</span>
                        <span class="rpDate"> ({{$t('du')+" " + timestampToDate((new Date(rp.config.date_start).getTime()/1000), "dayMonthNumber") +" " + $t('to') +" "+ 
                        timestampToDate((new Date(rp.config.date_end).getTime()/1000), "dayMonthNumber")}})</span>
                        <span v-if="indexRp < rps['rps'].length - 1"> / </span>
                    </span>
                </h4>
                <v-row>
                <v-col  v-for="indexC in parseInt(rps['coach_number'])" :key="'coach_'+index+''+indexC">
                    <vCoachSelector :loadData="loadData" :coachNumber="parseInt(rps['coach_number'])" :rps="rps['rps']" :operationId="operationId" :value="rps['coach_selected'][indexC - 1] ? rps['coach_selected'][indexC - 1] : {}" @removeModel="removeCoach(indexC - 1, index,  $event)" @input="testSelectCoach(indexC - 1, index,  $event)" :key="'selectorCoach_'+index+'_'+indexC+'_'+keySelector"/>
                </v-col>
            </v-row>
            </v-col>
        </v-row>
        <Loader :flat="true" v-if="loading" style="min-height:500px;"/>
    </div>
</template>
<script>
import Loader from '@/components/Loader'
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation'
import vCoachSelector from './vCoachSelector.vue';
export default {
    name: "coachsStepComponent",
    props: ["operationId", "loadData"],
    components:{FormDialogComponent, Loader, vCoachSelector},
    mixins: [getForm, dateManipulation],
    data() {
        return {
            loading:false,
            groupedRp:[],
            keySelector: 0
        }
    },
    destroyed() {
        this.groupedRp = []
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.refreshData();
                }
            }
        },
    },
    methods: {
        refreshData(){
            this.loading = true
            GenericDataService.getData("/operation/"+this.operationId+"/getGroupedMissionCoach").then((response) => {
                this.groupedRp = response.data.data;
                this.loading = false
            })
        },
        testSelectCoach(index, indexRp, $evt){
            this.groupedRp[indexRp]['coach_selected'][index] = $evt;
            this.updateTempCoach(indexRp);

        },
        removeCoach(index, indexRp, $evt){
            this.groupedRp[indexRp]['coach_selected'] = this.groupedRp[indexRp]['coach_selected'].filter((e) =>e.value != $evt);
            this.updateTempCoach(indexRp);
            
        },
        updateTempCoach(indexRp){
            let rpIds = this.groupedRp[indexRp]['rps'].map(rp => rp.id);
            GenericDataService.postData("/operation/"+this.operationId+"/setTempCoachs?rps_id="+JSON.stringify(rpIds), {"coach_selected" : this.groupedRp[indexRp]['coach_selected']}).then((response) => {
                this.refreshData();
                this.keySelector++;
                return true
            })
        }
    },
}
</script>
<style lang="scss">
    .coachStep {
        .rpName {
            font-weight:800;
            text-transform: none;
            font-size :14px;
        }
        .rpDate {
            font-weight:normal;
            font-size :12px;
            text-transform: none;
        }

    }
</style>