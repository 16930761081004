<template>
     <div>
            <DatatableV2 v-if="loadData" :displayTitle="false" :flat="true" :url="apiUrl" :switchUrl="switchUrl" :switchValue="'active'" :switchIds="'user_id'"/>
     </div>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
export default {
    name:"teamStepComponent",
    props:['operationId', 'loadData'],
    components: {DatatableV2},
    data() {
        return {
            //apiUrl:"/operation/getTeam",
            //apiUrl:"/entity/list",
        }
    },
    computed: {
        apiUrl(){
            return "/operation/"+this.operationId+"/getTeam";
        },
        switchUrl(){
            return "/operation/" + this.operationId + "/setTeamActive";
        },
    },
    watch: {

    },
}
</script>
<style lang="scss">

</style>
