<template>
    <v-container class="validationStep">
        <v-row >
            <v-col cols="6" class="text-left">
               <v-timeline dense>
                   <v-timeline-item
                        v-for="(item) in retroplanning"
                        :key="item.id"
                        color="primary"
                        :icon="item.settings.icon"
                        fill-dot
                         v-show="!item.config.workflow_id"
                        >
                        <v-card
                            tile class="no-shadow cardBorder">
                            <v-card-title>
                                <div>
                                    <div class="dateElement" v-if="item.config.date_start">
                                        {{parseFullDateToFr(item.config.date_start)}}
                                        <span v-if="item.config.date_end"> - {{parseFullDateToFr(item.config.date_end)}}</span>
                                    </div>
                                    <h3 v-if="item.config.name">{{item.config.name}}</h3>
                                    <h3 v-else> Pas de nom</h3>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <div class="configretro">
                                    <p v-if="item.config.prestataire" class="mb-1">
                                        <v-icon medium color="primary" class="mr-2">$rocket_launch_d</v-icon>
                                        {{item.config.prestataire.text}}
                                    </p>
                                    <p v-if="item.segmentation && item.segmentation.targetedContact && item.segmentation.plannedContact" class="mb-1">
                                        <v-icon medium color="primary" class="mr-2">$userfriends_d</v-icon>
                                        {{item.segmentation.targetedContact}} / {{item.segmentation.plannedContact}}
                                    </p>
                                    <p v-if="item.config.staff_qty" class="mb-1">{{$t('staff_qty')}} : {{item.config.staff_qty}}</p>
                                     <p v-if="item.config.template && item.templateData" class="mb-0">
                                         <v-icon medium color="primary" class="mr-2">{{item.config.canal === 'MAILING' ? '$envelope_open_text_d' : '$pencil_paintbrush_d'}}</v-icon>
                                         {{item.templateData.name}}
                                    </p>
                                     <p v-if="item.config.template_envelope && item.templateEnvelopeData" class="mb-1">
                                         <v-icon medium color="primary" class="mr-2">$envelope_d</v-icon>
                                         {{item.templateEnvelopeData.name}}
                                    </p>
                                    <p v-if="item.config.infomediaire" class="mb-0">{{$t('infomediaire')}} : {{item.config.infomediaire.text}}</p>
                                    <p v-if="item.config.box" class="mb-0">{{$t('boxType')}} : {{item.config.box.text}}</p>
                                </div>
                            </v-card-text>
                        </v-card>
                   </v-timeline-item>
               </v-timeline>
            </v-col>
            <v-col cols="6" class="pb-0">
                <v-simple-table v-if="users" class="pl-6">
                    <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left">
                                Intervenants
                            </th>
                            <th class="text-left">
                                Plannings
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in users"
                            :key="item.id"
                            >
                            <td>{{ item.name }}</td>
                            <td>
                                <span v-if="item.hasPlanning">
                                    <v-icon color="success" class="mr-2">$calendar_check_d</v-icon>
                                    {{$t('hasPlanning')}}
                                </span>
                                <span v-else>
                                    <v-icon color="error" class="mr-2">$calendartimes_d</v-icon>
                                    {{$t('hasNotPlanning')}}
                                </span>
                            </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <scheduleDashboardComponent :hoursReversed="hoursReversed" :hours="hours" :operationId="operationId" :noButton="true" :key="'hours_'+ref" :weekdays="operation.type === 'MARKETING'" class="no-shadow"/>
                <placesOperationComponent class="mt-6" :values="places" :operationId="operationId" :validationStep="true" :key="ref"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
import placesOperationComponent from '@/components/dashboardComponent/placesOperationComponent'
import dateManipulation from '@/mixins/dateManipulation'
import scheduleDashboardComponent from '@/components/dashboardComponent/scheduleDashboardComponent'
export default {
    name:"validationStepComponent",
    props:['operationId', 'loadData'],
    components: {DatatableV2, scheduleDashboardComponent, placesOperationComponent},
    mixins: [dateManipulation],
    data() {
        return {
           operation: {},
           hoursReversed: [],
           genericUnavailable: [],
           hours: [],
           places: [],
           users:[],
           ref:0
        }
    },
    computed: {
        retroplanning(){
            if(this.operation && this.operation.retroplanning){
                return this.operation.retroplanning.filter((e) => e.id != -1 && !e.config.entrypoint_id)
            }
            
        }
    },
    methods: {
        switchAutomatic(item){
            GenericDataService.postData('/operation/'+this.operationId+'/setConfigRetroPlanning', {campaign : item}).then((response) =>{
            })
        }
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.loading = true
                    GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
                        this.operation = response.data.data 
                        this.hours = response.data.data.hours 
                        this.places = response.data.data.places
                        this.operation.retroplanning.forEach(element => {
                            if(element.config && element.config.template && element.config.template != null){
                                GenericDataService.getData("/template/get?id="+(element.config.template.id ? element.config.template.id : element.config.template)).then((response) => {
                                    element.templateData = response.data.data
                                })
                            }
                            if(element.config && element.config.template_envelope && element.config.template_envelope != null){
                                GenericDataService.getData("/template/get?id="+(element.config.template_envelope.id ? element.config.template_envelope.id : element.config.template_envelope)).then((response) => {
                                    element.templateEnvelopeData = response.data.data
                                });
                            }
                            if(element.config && element.config.segmentation && element.config.segmentation.value) {
                                GenericDataService.getData("/operation/" + this.operationId + "/getClientFiles").then((response) => {
                                    element.segmentation = response.data.data.data.find(retro => retro.id === element.id);
                                })

                            }
                        });
                        GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                            this.hoursReversed = response.data.data.hours_text2
                            this.genericUnavailable = response.data.data.hours
                            GenericDataService.getData('/operation/'+this.operationId+'/getIntervenants').then((response) => {
                                this.users = response.data.data
                                this.ref++
                                this.loading = false
                            })
                            
                        }).catch((e) => {
                            this.loading = false
                        })
                    }).catch((e) => {
                        this.loading = false
                    })
                }
            }
        }
    },
}
</script>
<style lang="scss">
    .cardBorder {
        border: 1px solid rgba(0,0,0,0.1) !important;
        &::before, &::after {
            display:none;
        }
    }
    .validationStep {
        .configretro {
            .v-icon {
                font-size:18px !important;
            }
        }
        .cardFooter {
            border-top: 1px solid rgba(0,0,0,0.1) !important;
            display:flex;
            justify-content:flex-end;
            padding-right:10px !important;
            label {
                font-size:12px !important;
            }
        }
    }
    
</style>