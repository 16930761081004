<template>
    <v-container fluid v-if="itemLoaded">
        <v-row v-for="(campaign, index) in campaigns" :key="'campaign_'+index">
            <v-col cols="12">
                <h2>{{campaign.config.name}}</h2>
            </v-col>
            <v-col cols="12"  class="itemsContainer">
                <div :class="'create itemTemplate ' + campaign.config.canal" @click="getFormSpec('/template/getForm?retroplanning_id=' + campaign.id + '&formName=' + campaign.config.canal, ['EMAIL', 'MAILING', 'ENVELOPE'].includes(campaign.config.canal) ? true : false, campaign)">
                    <v-icon color="primary" large>$plus_l</v-icon>
                    <p>{{$t('addTemplate'+campaign.config.canal+'Label')}}</p>
                </div>
                <div v-for="(item) in campaign.items" :key="item.id" @click="displayTemplate(item, campaign)" :class="(campaign.config.template && campaign.config.template == item.id) ? 'itemTemplate selected '+campaign.config.canal : 'itemTemplate '+campaign.config.canal">
                    <div class="selectedOverlay">
                        <v-icon color="success" class="mr-3 mb-1" small>$check_l</v-icon>
                    </div>
                    <div class="overlayItem">
                        <v-icon color="white">$search_plus_r</v-icon>
                    </div>
                    <div v-if="item.canal == 'SMS' || item.canal == 'TEL'">
                         <div class="itemPreview" v-if="item.template"><span  class="template-span" v-html="item.template.text"></span></div>
                         <div class="itemName"><b>#T{{item.num}} - {{item.version}} - {{item.name}}</b></div>
                    </div>
                    <div v-if="item.canal == 'MMS'">
                        <div class="itemPreview MMS" v-if="item.template">
                            <span style="position:absolute; bottom:30px; left:10px;">{{item.template.text }}</span>
                            <img v-if="item.template.image" :src="item.template.image.thumb + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541">   
                        </div>
                         <div class="itemName"><b>#T{{item.num}} - {{item.version}} - {{item.name}}</b></div>
                    </div>
                    <div v-if="item.canal == 'VMS'">
                        <div class="itemPreview MMS" v-if="item.template && item.template.mp3">
                            <span style="position: absolute; bottom: 30px; left: 10px"><strong>{{$t('title')}} : </strong>{{ item.template.mp3.title }}</span>
                            <audio v-if="item.template.mp3.url" controls controlsList="nodownload" :src="item.template.mp3.url" style="width: 80%;"></audio>
                        </div>
                         <div class="itemName"><b>#T{{item.num}} - {{item.version}} - {{item.name}}</b></div>
                    </div>
                    <div v-if="item.canal == 'EMAIL'">
                         <div class="itemPreview email" v-if="item.template"><span class="template-span" v-html="item.template.html"></span></div>
                         <div class="itemName"><b>#T{{item.num}} - {{item.version}} - {{item.name}}</b></div>
                    </div>
                    <div v-if="item.canal == 'MAILING'" class="pdf">
                         <div class="itemPreview email" v-if="item.template">
                         <embed v-if="item.template.pdf && item.template.pdf.url" :src="item.template.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" type="application/pdf">
                         </div>
                         <div class="itemName"><b>#T{{item.num}} - {{item.version}} - {{item.name}}</b></div>
                    </div>
                </div>
            </v-col>
            <!-- AJOUT DE LA PARTIE ENVELOPPE SI LA CAMPAGNE EST MAILING -->
            <v-col cols="12" v-if="campaign.config.canal === 'MAILING'">
                <h2>{{campaign.config.name}} ({{ $t('envelope') }})</h2>
            </v-col>
            <v-col cols="12"  class="itemsContainer" v-if="campaign.config.canal === 'MAILING'">
                <div :class="'create itemTemplate ENVELOPE'" @click="getFormSpec('/template/getForm?retroplanning_id=' + campaign.id + '&formName=ENVELOPE', true, campaign)">
                    <v-icon color="primary" large>$plus_l</v-icon>
                    <p>{{$t('addTemplateENVELOPELabel')}}</p>
                </div>
                <div v-for="(item) in campaign.envelopeItems" :key="item.id" @click="displayTemplate(item, campaign)" :class="(campaign.config.template_envelope && campaign.config.template_envelope == item.id) ? 'itemTemplate selected ENVELOPE' : 'itemTemplate ENVELOPE'">
                    <div class="selectedOverlay">
                        <v-icon color="success" class="mr-3 mb-1" small>$check_l</v-icon>
                    </div>
                    <div class="overlayItem">
                        <v-icon color="white">$search_plus_r</v-icon>
                    </div>
                    <div class="pdf">
                         <div class="itemPreview email" v-if="item.template">
                         <embed v-if="item.template.pdf && item.template.pdf.url" :src="item.template.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" type="application/pdf">
                         </div>
                         <div class="itemName"><b>#T{{item.num}} - {{item.version}} - {{item.name}}</b></div>
                    </div>
                </div>
            </v-col>
            <!-- FIN -->
        </v-row>

        <v-dialog fullscreen scrollable v-model="templateDialog">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient">
                    <v-toolbar-title>{{$t("stepByStepOperationTitle")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined dark depressed @click.native="closeTemplateDetailDialog" class="ml-3">{{$t('cancelButton')}}</v-btn>
                    <v-btn outlined dark depressed @click="unselectTemplate" v-if="clickedCampaign && templateSelected && clickedCampaign.config && ((templateSelected.canal !== 'ENVELOPE' && clickedCampaign.config.template == templateSelected.id) || (templateSelected.canal === 'ENVELOPE' && clickedCampaign.config.template_envelope == templateSelected.id))" class="ml-3">{{$t('unselect')}}</v-btn>
                    <v-btn outlined dark depressed @click="selectTemplate" v-else class="ml-3">{{$t('select')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pa-6">
                    <templateDetailComponent :template="templateSelected" @refreshTemplate="refreshTemplate" :inStepper="true" :operationId="operationId" :inCampaignTemplatePage="true"  @addEntryPoint="addEntryPoint" :campaign="clickedCampaign" />
                </v-card-text>
            </v-card>
        </v-dialog>

        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'" />

        <dialogTemplateEmail v-if="templateEmailDialog" :templateId="templateEmailId" :templateDuplicatedId="templateDuplicatedEmailId" :openTemplateEmailDialog="templateEmailDialog" @recordTemplateDialog="recordTemplateDialog" @closeTemplateDialog="closeTemplateDialog"/>
        <TemplateVariableComponent v-if="templateForVariable":showTemplateModal="openTemplateVariableModal" :template="templateForVariable" :templateDuplicated="templateDuplicatedForVariable" @closeTemplateModal="closeTemplateVariable"/>
    </v-container>
</template>
<script>
import dialogTemplateEmail from '@/components/dialogs/dialogTemplateEmail'
import GenericDataService from '@/services/GenericDataService'
import templateDetailComponent from '@/components/templates/templateDetailComponent'
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import TemplateVariableComponent from '@/modules/templates/views/TemplateVariableComponent';
import getForm from '@/mixins/mixins';
import refreshData from '@/mixins/mixins';

export default {
    name: "templateStepComponent",
    components: {templateDetailComponent, FormDialogComponent, dialogTemplateEmail, TemplateVariableComponent},
    props: ["operationId", "loadData"],
    mixins: [ getForm , refreshData],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            templateDialog: false,
            itemLoaded: false,
            templates:[],
            templateSelected: {},
            clickedCampaign:{},
            form:null,
            templateEmailDialog: false,
            templateEmailId:0,
            templateDuplicatedEmailId:0,
            campaigns: [],
            openTemplateVariableModal: false,
            templateForVariable: null,
            templateDuplicatedForVariable: null,
            tplId:null,
        }
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    GenericDataService.getData('/operation/'+this.operationId+'/getCampsWithTemplates').then((response) => {
                        this.campaigns = response.data.data;
                        this.refreshTemplateList();
                    })
                    this.$emit('refreshRetroplanningAndOpe')
                }
            }
        },
        clickedCampaign(val){
        },
        tplId(val){
        }
    },
    beforeDestroy() {
        this.itemLoaded = false
    },
    methods: {
        customRefresh(){
            this.refreshTemplateList();
            this.refreshTemplate();
        },
        addEntryPoint($evt){
            this.getForm($evt);
        },
        recordTemplateDialog(){
            this.clickedCampaign.config.template = this.templateEmailId;
            GenericDataService.postData('/operation/' + this.operationId + '/setConfigRetroPlanning', {campaign : this.clickedCampaign}).then((response) =>{
                GenericDataService.getData("/operation/"+ this.operationId +"/getCompiledTemplate?opObject_id=" + this.clickedCampaign.id+"&tpl_id="+this.templateEmailId).then((response) => {
                    this.$emit('refreshRetroplanningAndOpe')
                    this.templateSelected = response.data.data;
                    this.refreshTemplateList();
                    this.templateDialog = true;
                    this.templateEmailDialog = false;
                    this.templateEmailId = 0;
                    this.templateDuplicatedEmailId = 0;
                    this.templateForVariable = null;
                    this.templateDuplicatedForVariable = null;
                });
            });
           
        },
        closeTemplateDetailDialog() {
            this.templateDialog = false;
            this.templateEmailId = 0;
            this.templateDuplicatedEmailId = 0;
            this.templateForVariable = null;
            this.templateDuplicatedForVariable = null;
            this.templateSelected = {};
        },
        refreshTemplate(){
            GenericDataService.getData("/operation/"+ this.operationId +"/getCompiledTemplate?opObject_id=" + this.clickedCampaign.id+"&tpl_id="+(this.tplId ? this.tplId : this.templateSelected.id)).then((response) => {
                this.templateSelected = response.data.data;
                //this.refreshTemplateList();
                //this.templateDialog = true;
            });
        },
        closeTemplateVariable(refresh = false){
            if(this.templateForVariable.canal === 'ENVELOPE')
                this.clickedCampaign.config.template_envelope = this.templateForVariable.id;
            else
                this.clickedCampaign.config.template = this.templateForVariable.id;

            GenericDataService.postData('/operation/' + this.operationId + '/setConfigRetroPlanning', {campaign : this.clickedCampaign}).then((response) => {
                this.$emit('refreshRetroplanningAndOpe')
                GenericDataService.getData("/operation/"+ this.operationId +"/getCompiledTemplate?opObject_id=" + this.clickedCampaign.id+"&tpl_id="+this.templateForVariable.id).then((response) => {
                    this.templateSelected = response.data.data;
                    this.refreshTemplateList();
                    this.openTemplateVariableModal = false;
                    this.templateDialog = true;
                    this.templateForVariable = null;
                    this.templateDuplicatedForVariable = null;
                });    
            });

            
        },
        closeTemplateDialog(){
            // this.clickedCampaign.config.template = this.templateEmailId;
            // GenericDataService.postData('/operation/' + this.operationId + '/setConfigRetroPlanning', {campaign : this.clickedCampaign}).then((response) =>{
            //     this.$emit('refreshRetroplanningAndOpe')
            // });

            this.refreshTemplateList();
            this.templateEmailDialog = false;
            this.templateDialog = false;
            this.templateSelected = {};
            this.templateEmailId = 0;
            this.templateDuplicatedEmailId = 0;
        },
        refreshTemplateList(){
            this.campaigns.forEach((campaign) => {
                GenericDataService.postData("/operation/" + this.operationId + "/getTemplateSelectList?retroplanning_id=" + campaign.id).then((response) => {
                    campaign.items = this._.cloneDeep(response.data.data.data);
                    this.$forceUpdate();
                    this.itemLoaded = true;
                });

                if(campaign.config.canal === 'MAILING') {
                    GenericDataService.postData("/operation/" + this.operationId + "/getTemplateSelectList?retroplanning_id=" + campaign.id + "&canal=ENVELOPE").then((response) => {
                        campaign.envelopeItems = this._.cloneDeep(response.data.data.data);
                        this.$forceUpdate();
                        this.itemLoaded = true;
                    });
                }
            });
        },
        displayTemplate(item, campaign){
            this.clickedCampaign = campaign;
            if(!this.templateSelected  || this.templateSelected.id !== item.id){
                GenericDataService.getData("/operation/"+ this.operationId +"/getCompiledTemplate?opObject_id=" + campaign.id+"&tpl_id="+item.id).then((response) => {
                    this.templateSelected = response.data.data;
                    this.templateDialog = true;
                });
            } else {
                this.templateDialog = true;
            }
        },
        selectTemplate(){
            let campaign = this.campaigns.find((e) => e.id == this.clickedCampaign.id)
            if(this.templateSelected.canal === 'ENVELOPE')
                campaign.config.template_envelope = this.templateSelected.id
            else
                campaign.config.template = this.templateSelected.id
            GenericDataService.postData('/operation/'+this.operationId+'/setConfigRetroPlanning', {campaign : campaign}).then((response) =>{
                //TODO : refresh retroplanning dans parent pour validation de la slide
                this.$emit('refreshRetroplanningAndOpe')
            })
            this.templateDialog = false;
        },
        unselectTemplate(){
            let campaign = this.campaigns.find((e) => e.id == this.clickedCampaign.id)
            if(this.templateSelected.canal === 'ENVELOPE')
                campaign.config.template_envelope = null;
            else 
                campaign.config.template = null;
            GenericDataService.postData('/operation/'+this.operationId+'/setConfigRetroPlanning', {campaign : campaign}).then((response) =>{
                //TODO : refresh retroplanning dans parent pour validation de la slide
                this.$emit('refreshRetroplanningAndOpe')
            })
            this.templateDialog = false;
        },
        getFormSpec(url, createForm = false, campaign = null) {
            this.formUrl = url
            this.$store.dispatch("forms/GET_FORM_DATA", { url: this.formUrl, payload: {} }).then((e) => {
                this.form = this.$store.state.forms.forms[e.reference];
            });
            setTimeout(() => {
                this.displayFormSpec(this.form.params.title, createForm, campaign);
            }, 200);
        },
        async displayFormSpec(title, createForm, campaign) {
          await this.$refs.componentForm.open(this.$t(title)).then((response) => {
              if(response && response.data && response.data.success) {
                    if(createForm) {
                        this.tplId = response.data.data.id
                        switch (response.data.data.canal) {
                            case 'EMAIL':
                                campaign.config.template = response.data.data.id
                                this.templateEmailId = response.data.data.id;
                                // GenericDataService.postData('/operation/'+this.operationId+'/setConfigRetroPlanning', {campaign : campaign}).then((response) =>{
                                //     //TODO : refresh retroplanning dans parent pour validation de la slide
                                //     this.$emit('refreshRetroplanningAndOpe')
                                    this.templateDuplicatedEmailId = this.templateSelected.id;
                                    this.templateEmailDialog = true;
                                    this.clickedCampaign = campaign;
                                // });
                                break;
                            case 'MAILING':
                            case 'ENVELOPE':
                                if(response.data.data.template.pdf){
                                    this.templateForVariable = response.data.data;
                                    this.templateDuplicatedForVariable = this.templateSelected;
                                    this.openTemplateVariableModal = true;
                                    this.clickedCampaign = campaign;
                                } else {
                                    this.formUrl = "/template/" + response.data.data.id + "/getEditor";
                                    //this.clickedCampaign = campaign;
                                    this.getFormSpec(this.formUrl);
                                }
                                break;
                            default:
                                this.formUrl = "/template/" + response.data.data.id + "/getEditor";
                                //this.clickedCampaign = campaign;
                                this.getFormSpec(this.formUrl);
                                break;
                        }
                    } else {
                        this.tplId = response.data.data.id
                        this.templateEmailDialog = false;
                        this.openTemplateVariableModal = false;
                        this.templateDialog = false;
                        this.templateSelected = {};
                    }
                    if(campaign){
                        this.clickedCampaign = campaign;
                    }
                    if(campaign && !this.templateEmailDialog) {
                        if(response.data.data.canal === 'ENVELOPE')
                            campaign.config.template_envelope = response.data.data.id;
                        else
                            campaign.config.template = response.data.data.id;
                        GenericDataService.postData('/operation/'+this.operationId+'/setConfigRetroPlanning', {campaign : campaign}).then((response) =>{
                            //TODO : refresh retroplanning dans parent pour validation de la slide
                            this.$emit('refreshRetroplanningAndOpe')
                        });
                    }
                    if(!this.templateEmailDialog && !this.templateEmailId && !this.openTemplateVariableModal){
                        GenericDataService.getData("/operation/"+ this.operationId +"/getCompiledTemplate?opObject_id=" + this.clickedCampaign.id+"&tpl_id="+this.tplId).then((response) => {
                            this.templateSelected = response.data.data;
                            this.refreshTemplateList();
                            this.templateDialog = true;
                        });
                    }
                    

                    //this.refreshTemplateList();
              }
          })  
        },
    },
}
</script>
<style lang="scss">
    .itemsContainer {
        display:flex;
        flex-wrap: wrap;
        .pdf {
            overflow:hidden;
        }
    }
    .itemTemplate {
        position:relative;
        height:200px;
        width:23%;
        margin-right:1%;
        margin-bottom:15px;
        border: 1px solid rgba(0, 0, 0, 0.13);
        background-color: rgba(0, 0, 0, 0.03);
        cursor:pointer;
        &.EMAIL, &.MAILING, &.ENVELOPE {
            height:300px;
            .itemPreview {
                height: 269px;
            }
        }
        &.create {
            border: 1px solid rgba(9, 87, 125, 0.2);
            background-color: rgba(9, 87, 125, 0.1);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                margin-bottom:0;
                text-transform: uppercase;
                margin-top:10px;
                font-weight:bold;
            }
        }
        .itemPreview {
            height: 169px ;
            padding:30px;
            display:flex;
            justify-content:center;
            align-items:center;
            position:relative;
            overflow: hidden;
            &.email {
                padding:0;
                align-items: flex-start;
                overflow:hidden;
                embed {
                    overflow: hidden;
                }
            }
            &.MMS {
                padding:0px;
                overflow:hidden;
                img {
                    width:100%;
                    height:auto;
                }
            }
            .template-span {
                top: 0;
                position: absolute;
            }
        }
        .itemName {
            height:30px;
            padding: 5px;
            border-top: 1px solid rgba(0, 0, 0, 0.13);
            border-bottom: 1px solid rgba(0, 0, 0, 0.13);
            background-color: white ;
        }
        .overlayItem {
            position:absolute;
            width:100%;
            height:100%;
            z-index:2;
            display:none;
            background-color: rgba(9, 87, 125, 0.6);
        }
        .selectedOverlay {
            position:absolute;
            display:none; 
            align-items: flex-end;
            justify-content: flex-end;
            z-index:2;
            width:100%;
            height:100%;
            background-color: rgba(91, 184, 93, 0.4);
            border: 1px solid rgba(91, 184, 93, 1);
        }
        &.selected {
            .selectedOverlay {
                display:flex;
            }
            &:hover {
                .overlayItem {
                    display:none;
                }
            }           
        }
        &:hover {
            .overlayItem {
                display:flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>