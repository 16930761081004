<template>
    <v-container class="pb-0" :style="'width:'+(!stepper ? '100%' : '50%')+'; min-height:'+(!stepper ? 'unset' : '500px')+'; overflow:hidden;'">
        <v-row>
            <v-col cols="12" class="py-0 pt-2" v-if="stepper && $func.hasRight('place/add')">
                <v-btn  depressed  class="mb-4 bg-gradient" @click="openForm"><v-icon small class="mr-2">$plus_l</v-icon> Ajouter un lieu</v-btn>
            </v-col>
            <div class="iconEdit" v-else-if="!stepper && !validationStep && $func.hasRight('place/add')">
                <v-btn  depressed  class="mb-4 bg-gradient" @click="openForm"><v-icon small class="mr-2">$plus_l</v-icon>{{$t('add') }}</v-btn>
            </div>
            <v-col cols="12" class="py-0 pb-4" v-if="places.length > 0">
                <v-card rounded="lg" :class="stepper ? 'mb-3 ' : ' no-shadow placeCard'" v-for="(place, index) in places" :key="index" @click="$func.hasRight('place/update') ? editPlace(place) : ''">
                    <v-card-text class="cardOpe" v-if="!stepper && !validationStep">
                        <b>{{place.config.name}}</b>
                        <v-menu offset-y left >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.stop=""  class="square float-right" depressed text>
                                    <v-icon >$dots_l</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item  small depressed text v-if="$func.hasRight('place/update')" @click="editPlace(place)">
                                    <v-icon small class="mr-2" color="primary">$pencil_d</v-icon>{{ $t('edit') }}
                                </v-list-item>
                                <v-list-item  small color="error" v-if="places.length > 1 && $func.hasRight('place/delete')" depressed text @click="getDeleteConfirmationItem(place.id)">
                                    <v-icon small class="mr-2" color="error">$trashalt_d</v-icon>{{ $t('delete') }}
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-card-text>
                    <v-card-text class="text-adress " v-else>
                        <div class="icon" v-if="!validationStep">
                            <v-btn fab small depressed text v-if="$func.hasRight('place/update')" @click="editPlace(place)"><v-icon small>$pencil_d</v-icon></v-btn>
                            <v-btn fab small color="error" v-if="places.length > 1 && $func.hasRight('place/delete')" depressed text @click.stop="getDeleteConfirmationItem(place.id)"><v-icon small>$trashalt_d</v-icon></v-btn>
                        </div>
                        <b class="mb-2">{{place.config.name}}</b>
                        <p v-if="place.config.adress" class="mb-0" v-html="place.config.adress"></p>
                        <p class="mb-0" v-if="place.config.city">
                            <span v-if="place.config.zip && place.config.zip != ''">{{ place.config.zip }} - </span>{{place.config.city}}
                        </p>
                        <p v-if="place.config.country" class="mb-0" v-html="place.config.country"></p>

                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" class="pb-4" v-else>
                <v-alert
                    border="left"
                    class="mt-0 mb-0"
                    text
                    dense
                    color="info"
                    >
                    <template v-slot:prepend>
                        <v-icon color="info" class="mr-3" small>
                            $warning_s
                        </v-icon>
                    </template>
                    {{$t('noPlacesFound')}}
                </v-alert>
            </v-col>
            <v-dialog width="600" v-model="dialogConfirm">
                <v-card>
                    <v-toolbar flat dark color="primary" class="toolbar" :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover; height:63px;'">
                        <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                            {{$t('cancelLabel')}}
                        </v-btn>
                        <v-btn depressed outlined color="white" @click="removePlace">
                            {{$t('confirmLabel')}}
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pt-3">
                        {{$t('confirmDeletePlaceText')}}
                    </v-card-text>
                </v-card>
            </v-dialog>
            <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'"
            />
            <Loader :flat="true" v-if="loading" style="height:100%;"/>
        </v-row>
    </v-container>
</template>
<script>
import Loader from '@/components/Loader'
import Editor from "@tinymce/tinymce-vue";
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import GenericDataService from '@/services/GenericDataService';
import getForm from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation'
export default {
    name:"placesStepForm",
    components: {Editor, FormDialogComponent, Loader},
    mixins: [getForm, dateManipulation],
    props: ["operationId", "loadData", 'stepper', 'validationStep'],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            dialogConfirm:false,
            places: [],
            dialogTitle: "addPlace",
            form:null,
            itemToDelete: null,
            loading:false,
            steps: [],
        }
    },
    destroyed() {
        this.steps = []
    },
    watch: {
        validationStep: {
            //immediate:true,
            handler(val){
                this.customRefresh();
            }
        },
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.loading = true,
                    GenericDataService.getData('/operation/'+this.operationId+'/getPlaces').then((response) => {
                        this.places = response.data.data
                        GenericDataService.getData('/operation/'+this.operationId+'/getRetroplanning').then((response) => {
                            this.steps = response.data.data;
                            this.loading = false
                            this.$emit('refreshRetroplanningAndOpe')
                        })
                    }).catch((e) => {
                        this.loading = false
                    })
                }
            }
        },
        steps(newSteps) {
            if(this.steps && this.steps.length > 0) {
                let stepCampOutArray = this.steps.filter((e) => e.type.match(/^retroplanning.CAMP_OUT/))
                let stepCampInArray = this.steps.filter((e) => e.type.match(/^retroplanning.CAMP_IN/))
                let stepCampCallArray = this.steps.filter((e) => e.type.match(/^retroplanning.CAMP_CALL/))
                //si au moins une campagne sortante
                if(stepCampOutArray.length > 0 || stepCampCallArray.length > 0){
                    this.$emit('addStep', 'templates')
                    //this.$emit('addStep', 'clientsFile')
                    this.$emit('addStep', 'contactList')
                } else {
                    this.$emit('removeStep', 'templates')
                    //this.$emit('removeStep', 'clientsFile')
                    this.$emit('removeStep', 'contactList')
                }
                //si au moins une campagne sortante ou entrante
                // if(stepCampOutArray.length > 0 || stepCampInArray.length > 0){
                //     this.$emit('addStep', 'entryPoints')
                // } else {
                //     this.$emit('removeStep', 'entryPoints')
                // }
            }
        },
    },
    // mounted() {
    //     this.customRefresh();
    // },
    methods: {
        customRefresh() {
            GenericDataService.getData('/operation/'+this.operationId+'/getPlaces').then((response) => {
                this.$emit('refreshRetroplanningAndOpe')
                this.places = response.data.data
            }).catch((e) => {
                this.loading = false
            })
        },
        openForm(){
            this.getForm('/operation/'+this.operationId+'/getFormPlaces');
        },
        editPlace(place){
            this.getForm('/operation/'+this.operationId+'/getFormPlaces?id='+place.id)
        },
        closeDialog(){
            this.itemToDelete = null
            this.dialogConfirm = false
        },
        removePlace(){
            GenericDataService.getData('/operation/'+this.operationId+'/deletePlace?id='+this.itemToDelete).then((response) => {
                this.$emit('refreshRetroplanningAndOpe')
                this.places = response.data.data
                this.dialogConfirm = false
                this.itemToDelete = null
            }).catch((e) => {
                this.loading = false
            })
        },
        getDeleteConfirmationItem(idItem){
            this.getForm('/operation/checkBeforeDeletePlace?place_id='+idItem+'&operation_id='+this.operationId);
            // this.itemToDelete = idItem
            // this.dialogConfirm = true
        }
    },
}
</script>
<style lang="scss">
.placeCard {
    &:hover {
                background-color: rgba(0,0,0,0.05) !important;
            }
}
    .cardOpe {
        margin-bottom:8px;
        border:1px solid #CCC;   
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .text-adress {
        .icon {
            position:absolute;
            top:15px;
            right:10px;
        }
    }
</style>
