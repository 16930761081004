<template>
  <v-card :tile="noButton" :class="noButton ? 'pa-3 pt-0 mt-6' : 'rounded-lg pa-3 pt-0 mt-6'">
    <v-card-title class="px-3 pb-4 pt-2">
      <h2>{{$t('operationHours')}}</h2>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text :class="noButton ? 'px-3 ' : 'px-3'">
      <hoursStepComponent class="px-0 pb-0" :loadData="true" :noButton="noButton" :operationId="operationId" :weekdays="weekdays" :stepper="false"/>
    </v-card-text>
  </v-card>
</template>
<script>
import hoursStepComponent from '@/modules/operations/operationsComponents/hoursStepComponent'
import Planning from '@/components/planning/Planning'
import planningMixins from '@/mixins/planningMixins'
import dateManipulation from '@/mixins/dateManipulation'
import GenericDataService from '@/services/GenericDataService'
export default {
  name: "scheduleDashboardComponent",
  components: {Planning, hoursStepComponent},
  mixins:[planningMixins, dateManipulation],
  props: ['hours', 'hoursReversed', 'entityId', 'operationId', 'noButton', 'weekdays'],
  data() {
    return {
        publicPath:process.env.BASE_URL,
        dialog: false,
        planningKey:0,
        planning: {
          type : 'INDISPO_CONCESSION',
        },
        genericUnavailable: [

        ],
    }
  },
  created() {

  },
  computed: {
      daysArray: function(){
          return ['mondayLabel', 'tuesdayLabel', 'wednesdayLabel', 'thursdayLabel', 'fridayLabel', 'saturdayLabel', 'sundayLabel']
      }
  },
  methods: {
      recordHours : function(){
        let id = this.$route.params.id
        // GenericDataService.postData('/entity/'+id+'/setHours', this.genericUnavailable).then((response) =>{
        //   this.$store.dispatch('entities/SET_HOURS', this.genericUnavailable).then((e) => {
        //     this.$store.dispatch("SET_DATA", {
        //       url: "/entity/get?id=" + this.$route.params.id,
        //       mutation: "entities/setEntityData",
        //       data:{},
        //     }).then((e) => {
        //       this.dialog = false
        //     })
        //   })
        // })
      },
      cancel: function(){
        this.genericUnavailable = this._.cloneDeep(this.hours)
        this.dialog = false
      },
      addAllUnavailable(day){
            //let unavailable = this.genericUnavailable.findIndex((e) => e.day == day)
            let fullSlot = {}
            fullSlot.start = "00:00"
            fullSlot.end = "24:00",
            fullSlot.type = "hebdomadaire",
            fullSlot.source ="entity"
            fullSlot.day = day
            this.genericUnavailable.push(fullSlot)
        },
        removeAllUnavailable(day){
            let unavailableArray = this.genericUnavailable.filter((e) => e.day == day)
            unavailableArray.forEach((e) => {
              let unavailableIndex = this.genericUnavailable.findIndex((e) => e.day == day)
              this.genericUnavailable.splice(unavailableIndex, 1)
            })
            //unavailable.slots = [];
        },
        //Ajout d'une indispo
        addItem:function(event, unavailableData) {
            //let slot = this.genericUnavailable.find((e) => e.day == unavailableData.day)
            let item = this._.cloneDeep(unavailableData);
            item.start = this.parseFloatToHour(this.parseHourToFloat(unavailableData.start))
            item.end = this.parseFloatToHour(this.parseHourToFloat(unavailableData.end))
            //check si une indispo est présente avant et apres
            let itemBefore = this.genericUnavailable.find((e) => e.day == unavailableData.day && e.end == item.start);
            let itemAfter = this.genericUnavailable.find((e) => e.day == unavailableData.day && e.start == item.end);
            let itemUnder = this.genericUnavailable.find((e) => e.day == unavailableData.day && e.start >= item.start && e.end <= item.end && e.end != "00:00")
            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.genericUnavailable.findIndex((e) => e.day == unavailableData.day && e.start == item.end);
                itemBefore.end = itemAfter.end
                this.genericUnavailable.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                itemBefore.end = item.end
            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
            } else if(itemUnder){
                let itemUnderIndex = this.genericUnavailable.findIndex((e) => e.day == unavailableData.day && e.start == itemUnder.start && e.end == itemUnder.end)
                this.genericUnavailable.splice(itemUnderIndex, 1)
                this.genericUnavailable.push(item);
            } else { // sinon on le push
                this.genericUnavailable.push(item);
            }
        },
        //Suppression d'une indispo
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable.findIndex((e) => e.day == slot.day && e.start == slot.start)
            //let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            this.genericUnavailable.splice(dayItem, 1)
        },
  },
  watch: {
    hours: function(newVal){
         this.genericUnavailable = this._.cloneDeep(this.hours)
    }
  },
};
</script>
<style lang="scss">
  .borderButton {
    border:1px solid rgba(0,0,0,0.1) !important;
  }
</style>
