<template>
    <div>
        <v-container fluid class="pa-6">
            <DatatableV2 :displayTitle="false" :flat="false" @openLibraryDialog="openLibraryDialog" :url="apiUrl" :key="currentKey" ref="datatableOpe" />
            <LibraryDialogComponent v-if="formLib" :form="formLib" :libType="libType" @cancel="formLib = null;" :showLibraryDialog="showLibraryDialog" @hideLibraryDialog="showLibraryDialog = false;" />
        </v-container>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import DatatableV2 from '@/components/DatatableV2';
import LibraryDialogComponent from "@/components/dialogs/LibraryDialogComponent";

export default {
    name: 'OperationsList',
    components: { DatatableV2, LibraryDialogComponent, },
    data() {
        return {
            apiUrl:"/operation/list",
            currentKey:0,
            formLib: null,
            libType: "OPERATIONTYPE",
            showLibraryDialog: false,
        }
    },
    created() {
        console.log(this.$route);
    },
    methods: {
        openLibraryDialog() {
            GenericDataService.getData("/library/getFilters?libType=" + this.libType).then((response) => {
                this.formLib = response.data.data;
                this.showLibraryDialog = true;
            });
        },
    },
}
</script>