<template>

        <v-row>
            <v-col cols="12">

                <div class="actionHeader mt-3 mb-4">
                    <v-btn  depressed class="bg-gradient" @click="displayUpload = true">
                        <v-icon small color="white" class="mr-3">$plus_l</v-icon>
                        {{$t('ImportContact')}}
                    </v-btn>
                    <div style="width:300px;">
                        <v-select hide-details :items="segments" v-model="segmentSelected"  width="200" :placeholder="$t('choose_segment')" :no-data-text="$t('no-data-text')">
                            <template v-slot:prepend-item>
                                <v-list-item ripple @click="addSegmentation">
                                    <v-list-item-content>
                                        <v-list-item-title> {{ $t("createNew") }} </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                            </template>
                        </v-select>
                    </div>
                    <v-dialog v-model="displaySegmentation" fullscreen>
                        <v-card>
                            <v-toolbar tile flat dark  :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
                                <v-toolbar-title>{{$t('choiceSegment')}}</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                                <SegmentationComponent @closeSegmentModal="displaySegmentation = false" @recordedSegment="recordedSegment" />
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <importContactComponent @closeModal="closeModal" :displayUpload="displayUpload" :operationId="operationId" />
                </div>
                <div class="stats">
                    <NumberStatCardComponent class="NumberStatCardComponent" v-for="(stat, index) in baseStat" :classProp="index == 0 ? 'fixed' : ''" :key="'baseStat_'+index" :item="stat" />
                    <!--div v-for="(stat, index) in baseStat" :key="'baseStat_'+index" class="stat pa-4">
                        <v-icon large color='primary' class="mb-2">{{stat.icon}}</v-icon>
                        <div class="number"><span>{{stat.number}}</span>/{{stat.total}}</div>
                        <div class="label">{{$t(stat.label)}}</div>
                    </div-->
                </div>
                <!-- <v-expand-transition>
                    <div class="stats" v-show="expandSecondaryStat">
                        <div v-for="(stat, index) in secondaryStat" :key="'baseStat_'+index" class="stat secondaryStat pa-4">
                            <v-icon large color='primary' class="mb-2">{{stat.icon}}</v-icon>
                            <div class="number"><span>{{stat.number}}</span>/{{stat.total}}</div>
                            <div class="label">{{stat.label}}</div>
                        </div>
                    </div>
                </v-expand-transition>
                <a @click="expandSecondaryStat = !expandSecondaryStat" class="mt-1" style="text-decoration:underline; display:block">Plus de statistiques</a> -->
            </v-col>
            <v-col cols="12">
                <DatatableV2 :key="this.datatableKey" v-if="loadData" :displayTitle="false"  :url="apiUrl" :switchUrl="switchUrl" :switchIds="'id'"/>
            </v-col>
        </v-row>


</template>
<script>
import SegmentationComponent from '@/modules/segmentations/views/SegmentationComponent'
import importContactComponent from '@/components/importContactComponent'
import DatatableV2 from '@/components/DatatableV2'
import GenericDataService from '@/services/GenericDataService'
import NumberStatCardComponent from '@/components/dashboardComponent/NumberStatCardComponent'

export default {
    name: "clientFileComponent",
    components: {DatatableV2, importContactComponent, GenericDataService, SegmentationComponent, NumberStatCardComponent},
    props: ["operationId", "loadData"],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            expandSecondaryStat: false,
            stats: [],
            segmentSelected: 0,
            displayUpload: false,
            displaySegmentation: false,
            segments:[],
            operation: {},
            datatableKey: 0,
        }
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.loading = true;
                    GenericDataService.getData('/operation/' + this.operationId + '/get').then((response) => {
                        this.operation = response.data.data;
                        if(this.operation.config) {
                            this.segmentSelected = 0;
                            this.recordedSegment(this.operation.config.segment_id);
                        }
                        this.loading = false;
                    }).catch((e) => {
                        this.loading = false;
                    });
                }
            }
        },
        segmentSelected(val) {
            if(val !== 0)
                this.loadStats();
        },
    },
    methods: {
        addSegmentation() {
            this.displaySegmentation = true
        },
        closeModal(reload = false) {
            this.displayUpload = false;
            if(reload)
                this.loadStats();
        },
        recordedSegment(id){
            GenericDataService.getData('/segment/_selectList').then((response) => {
                this.segments = response.data.data;
                this.segmentSelected = id;
            });
            this.displaySegmentation = false;
        },
        loadStats() {
            GenericDataService.getData('/operation/' + this.operationId + '/getStatsBySegment?segment_id=' + this.segmentSelected).then((response) => {
                this.stats = response.data.data.detail;
                this.datatableKey = this.datatableKey + 1;
            });
        }
    },
    computed: {
        baseStat(){
            let stats = this._.cloneDeep(this.stats)
            return stats.splice(0,5)
        },
        secondaryStat(){
            let stats = this._.cloneDeep(this.stats)
            return stats.splice(5, this.stats.length+1)
        },
        apiUrl(){
            return "/operation/" + this.operationId + "/getClientFiles"
        },
        switchUrl(){
            return "/operation/" + this.operationId + "/setClientFileActiveByType"
        },
    },
}
</script>
<style lang="scss">

    .NumberStatCardComponent{
        margin-right: 15px;

        &:last-child{
            margin-right:0;
        }
    }
    .stats {
        display:flex;
        align-items:center;
        justify-content: center;
        .stat {
            width:20%;
            min-height: 130px;
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border:1px solid rgba(0,0,0,0.12);
            border-right-width:0;
            &:last-child {
                border-right-width:1px;
            }
            .number {
                margin-top:5px;
                font-weight:bold;
                font-size:18px;
                span {
                    font-size:26px;
                }
            }
            .label {
                text-transform: uppercase;
                margin-top:5px;
            }
            &.secondaryStat {
                border-top-width: 0;
            }
        }
    }
    .actionHeader {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
