<template>
    <v-dialog fullscreen no-click-animation persistent scrollable v-model="displayOperationStepper" >
         <v-card>
             <v-toolbar tile flat dark  :style="'background-color:var(--v-primary-base); max-height:64px;'">
                <v-btn depressed color="transparent" class="mr-2" @click.native="goBack"><v-icon dark small>$prev</v-icon> {{$t("back")}}</v-btn>
                 <v-spacer></v-spacer>
                <v-toolbar-title v-if="operation && operation.config">{{operation.name}}<span v-if="operation.config.date_start && operation.type !== 'MARKETING'"> : {{parseFullDateToFr(operation.config.date_start)}} - {{parseFullDateToFr(operation.config.date_end)}}</span></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined dark depressed @click.native="finishLater">{{$t('finishLaterButton')}}</v-btn>
                <v-btn outlined dark depressed @click.native="letConfigureBy" class="ml-3">{{$t('letConfigureByButton')}}</v-btn>
                <v-btn outlined dark depressed v-if="stepper >= 1 && stepper != (displayedStep.length)" @click="nextTab(stepper)" class="ml-3">{{$t('next')}}</v-btn>
                <v-btn outlined dark depressed :loading="loader" v-if="stepper == (displayedStep.length)" @click="validateConfig" class="ml-3">{{$t('validateConfiguration')}}</v-btn>
                <v-dialog width="300" v-model="errorDialog" permanent>
                    <v-card>
                        <v-card-title class="error pa-1 pl-4 white--text justify-space-between">    
                            <span>
                                <v-icon text dark class="mr-2">$warning_d</v-icon>
                                {{$t('error')}}
                            </span>
                            <v-icon dark text @click="errorDialog = false" class="pr-4">$close</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <p class="error--text pt-6" style="font-size:15px;">{{$t(errorTxt)}}</p>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-toolbar>
            <v-card-text class="pa-0 stepperOpe">
                <template >
                
                <v-stepper-icon v-model="stepper" flat tile alt-labels class="no-shadow" :key="stepperKey">
                    <v-stepper-header class="no-shadow headerStepper">
                            <template v-for="(step, index) in displayedStep">
                                <v-stepper-step-icon :editable="step.complete" :edit-icon="step.icon"  :icon="step.icon" v-if="step.display" :class="step.complete ? 'icon completeStep' : 'icon'" :complete="stepper > index + 1" :step="index + 1" :key="step.component+'_'+currentKey">
                                    {{$t(step.stepTitle)}}
                                </v-stepper-step-icon>
                                <v-divider :key="'divider_'+index" v-if="step.display && index < (displayedStep.length - 1)"></v-divider>
                            </template>

                        <!-- <template v-for="(step, index) in steps">
                            <v-stepper-step :complete="stepper > index + 1" :step="index + 1" :key="index">
                                {{$t(step.stepTitle)}}
                            </v-stepper-step>
                            <v-divider :key="'divider_'+index" v-show="index < (steps.length - 1)"></v-divider>
                        </template>                        -->
                    </v-stepper-header>
                    <v-stepper-items class="itemStepper">
                        <template v-for="(step, index) in displayedStep">
                        <v-stepper-content v-if="step.display" :step="index + 1" :key="'content_'+index" class="py-0 px-0" >
                            <v-card class="mainCardStep" >
                                <v-card-text class="px-0 pt-0">
                                    <v-container v-if="step.component">
                                        <v-row class="justify-center">
                                            <v-col cols="12">
                                                <div class="banner">
                                                    <div class="imgContent mr-6">
                                                        <v-img
                                                                :src= "publicPath+'img/marketing-direct.png'"
                                                                height="120"
                                                                max-width="120"
                                                                contain
                                                        ></v-img>
                                                    </div>

                                                    <div class="content-text">
                                                        <p class="bannerTitle">{{$t(step.title)}}</p>
                                                        <p class="mb-0" v-html="$t(step.comment)" ></p>
                                                        
                                                    </div>
                                                </div>
                                            </v-col>
                                            
                                        </v-row>

                                        <v-row class="justify-center">
                                            <v-col cols="12">

                                                <Component
                                                    :is="step.component"
                                                    :loadData="(index == 0) ? loadFirstData : ((index == stepper - 1) ? true : false)"
                                                    :stepper="true"
                                                    :operationId="operationId"
                                                    :errorStep="errorTxt"
                                                    :operation="operation"
                                                    @addStep="addStep"
                                                    @removeStep="removeStep"
                                                    @refreshRetroplanningAndOpe="refreshRetroplanningAndOpe"
                                                    ref="stepComponent"
                                                    :key="step.component+'_'+currentKey"
                                                    />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-stepper-content>
                        </template>
                    </v-stepper-items>
                </v-stepper-icon>
                </template >
                
            </v-card-text>
            
         </v-card>
         <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'"
            />
           <notifyCoachDialog :rps="notifyCoachRP" :operationId="operationId" :openDialog="openNotifyDialog" @cancel="openNotifyDialog = false" @goNextAfter="goNextAfter"/> 
    </v-dialog>
</template>
<script>
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import retroplanningStepForm from '@/modules/operations/operationsComponents/retroplanningStepForm'
import inscriptionStepForm from '@/modules/operations/operationsComponents/inscriptionStepForm'
import placesStepForm from '@/modules/operations/operationsComponents/placesStepForm'
import hoursStepComponent from '@/modules/operations/operationsComponents/hoursStepComponent'
import teamStepComponent from '@/modules/operations/operationsComponents/teamStepComponent'
import attributionRdvStepComponent from '@/modules/operations/operationsComponents/attributionRdvStepComponent'
import teamAttributionStepComponent from '@/modules/operations/operationsComponents/teamAttributionStepComponent'
import templatesStepComponent from '@/modules/operations/operationsComponents/templatesStepComponent'
import clientFileComponent from '@/modules/operations/operationsComponents/clientFileComponent'
import ressourcesStepComponent from '@/modules/operations/operationsComponents/ressourcesStepComponent'
import validationStepComponent from '@/modules/operations/operationsComponents/validationStepComponent'
import contactListComponent from '@/modules/operations/operationsComponents/contactListComponent'
import templateStepComponent from '@/modules/operations/operationsComponents/templateStepComponent'
import workflowStepComponent from '@/modules/operations/operationsComponents/workflowStepComponent'
import coachsStepComponent from '@/modules/operations/operationsComponents/coachsStepComponent.vue'
import notifyCoachDialog from '@/modules/operations/operationsComponents/notifyCoachDialog.vue'
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'
import getForm from "@/mixins/mixins";
export default {
    name:"OperationStepperDialog",
    props: ['displayOperationStepper', 'operationId'],
     mixins: [getForm, dateManipulation],
    components: {
        coachsStepComponent, notifyCoachDialog, workflowStepComponent, templateStepComponent, contactListComponent, teamAttributionStepComponent, retroplanningStepForm, inscriptionStepForm, placesStepForm, hoursStepComponent, teamStepComponent, attributionRdvStepComponent, templatesStepComponent, clientFileComponent, ressourcesStepComponent, validationStepComponent,FormDialogComponent
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            stepper:1,
            loadFirstData : false,
            steps: [
                {
                    stepTitle: "retroplanning",
                    title: "WhichkeyStepTitle",
                    comment: "WhichKeyStepComment",
                    component: "retroplanningStepForm",
                    icon:"$calendar_alt_l",
                    display:true
                }
            ],
            retroplanning:[],
            templateValid : false,
            placesValid: false,
            form:null,
            currentKey:0,
            stepperKey:0,
            errorDialog: false,
            errorTxt:"",
            operation: {},
            loader: false,
            notifyCoachRP:[],
            openNotifyDialog: false
        }
    },
    destroyed() {
        this.stepper = 1
    },
    watch: {
        //watch les steps pour les enregistrer dès qu'il y a une maj (complete ou display)
        steps: {
            deep: true,
            handler(val){
                if(val.length > 0){
                    GenericDataService.postData('/operation/'+this.operationId+'/setStepper', this.steps).then((response) => {
                    })
                }
            }
        },
        //Initialisation des datas, quand la dialog s'ouvre
        displayOperationStepper(val){
            if(val == true){
                GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
                    this.operation = response.data.data
                    this.steps = response.data.data.stepper
                    this.setStepper()
                    this.loadFirstData = true
                })
            } else {
                this.loadFirstData = false
            }
        }
    },
    computed: {
        displayedStep() {
            return this.steps.filter((e) => e.display)
        }
    },
    methods: {
        validateConfig(){
            this.steps[this.displayedStep.length - 1]['complete'] = true;
            this.loader = true
            GenericDataService.postData('/operation/'+this.operationId+'/validate').then((response) =>{
                 GenericDataService.getData('/menu/getsubmenu?module=operation&id='+this.operationId).then((response) => {
                    let submenu = response.data.data;
                    this.$store.dispatch('base/SET_SUB_MENU', submenu);
                    this.loader = false
                    this.$emit('StepperFinished');
                })
            })
        },
        setStepper(){
            let stepperIndex = 1;
            let count = 0;
            for (let index = 0; index < this.steps.length; index++) {
                const element = this.steps[index];
                if(element.complete == true && element.display == true){
                    stepperIndex = (count + 1)
                    count++;
                }
            }
            this.stepper = stepperIndex
            setTimeout(() => {
                this.stepperKey++
                this.currentKey++
            }, 200);

            //this.$refs.stepComponent.$forceUpdate()
        },
        refreshRetroplanningAndOpe(){
            GenericDataService.getData('/operation/'+this.operationId+'/getRetroplanning').then((response) => {
                this.retroplanning = response.data.data
                GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
                    this.operation = response.data.data
                    this.checkIfValid()
                })
            })
        },
        checkIfValid(){
            //check de la validation template
            let stepCampOutArray = this.retroplanning.filter((e) => e.type.match(/^retroplanning.CAMP_OUT/))
            this.templateValid = true;
            stepCampOutArray.forEach(element => {
                if(!element.config.template || element.config.template == null){
                    this.templateValid = false;
                } else if(element.config.canal === 'MAILING' && (!element.config.template_envelope || element.config.template_envelope == null)) {
                    this.templateValid = false;
                }
            });

            //Check des lieux
            if(this.operation && this.operation.places.length > 0){
                this.placesValid = true
            }
        },
        //Ajout des steps en fonction du retroplanning
        addStep(stepName){
            let step = this.steps.find((e) => e.stepTitle == stepName)
            if(step && !step.workflowHasTemplate)
                step.display = true
        },
        //Effacement des steps en fonction du retroplanning
        removeStep(stepName) {
            let step = this.steps.find((e) => e.stepTitle == stepName)
            if(step && !step.workflowHasTemplate)
                step.display = false
        },
        goNextAfter(){
            this.openNotifyDialog = false
            this.displayedStep[this.stepper - 1]['complete'] = true
            setTimeout(() => {
                this.stepper++
            }, 100);
        },
        letConfigureBy() {
            this.getForm('/operation/'+this.operationId+'/getForm?formName=configureBy');
        },
        customRefresh() {
            this.$emit('afterConfigureBy')
        },
        goBack() {
            if(this.stepper == 1){
                this.stepper = 1
                this.$emit('closeModal')
            } else {
                this.stepper --
            }

        },
        finishLater() {
            this.stepper = 1
            this.$emit('closeModal')
        },
        nextTab(stepNb) {
            this.errorTxt = '';
            switch (this.displayedStep[stepNb - 1].title) {
                case 'templateStepTitle':
                    if(1 == 1){ // Demande de Baptou #1074721160
                    // if(this.templateValid){
                        this.displayedStep[stepNb - 1]['complete'] = true
                        this.errorTxt = ""
                        setTimeout(() => {
                            this.stepper++
                        }, 100);
                    } else {
                        //TODO : gérer l'erreur des templates non selected
                        this.errorDialog = true
                        this.errorTxt = "selectTemplateForCampError"
                    }
                    break;
                case "WhereEventTitle":
                    if(this.placesValid){
                        this.displayedStep[stepNb - 1]['complete'] = true
                        this.errorTxt = ""
                        setTimeout(() => {
                            this.stepper++
                        }, 100);
                    } else {
                        this.errorDialog = true
                        this.errorTxt = "atLeastOnePlaceError"
                    }
                    break;
                case 'WhichkeyStepTitle':
                case 'coachsStepTitle':
                    GenericDataService.getData('/operation/'+this.operationId+'/checkCoachToNotify').then((response) =>{
                        if(response.data.data.length > 0){
                            this.notifyCoachRP = response.data.data
                            this.openNotifyDialog = true
                        } else {
                            this.displayedStep[stepNb - 1]['complete'] = true
                            setTimeout(() => {
                                this.stepper++
                            }, 100);
                        }
                    })
                    
                    break;
                default:
                    this.displayedStep[stepNb - 1]['complete'] = true
                    setTimeout(() => {
                        this.stepper++
                    }, 100);
                    break;
            }
        }
    },
}
</script>
<style lang="scss">

.stepperOpe{
    background-color: var(--v-lightgrey-base);

    .headerStepper{
        background-color: var(--v-lightgrey-base) !important;
    }

    .mainCardStep{
        background-color:var(--v-lightgrey-base);
    }

    .v-stepper__step__step {
        width:45px !important;
        height:45px !important;
    }
    .v-stepper--alt-labels .v-stepper__header .v-divider {
        margin: 47px -67px 0 !important;
    }
    .icon {
        &.v-stepper__step--inactive {
            &.completeStep {
                .v-stepper__step__step {
                    background: white !important;
                    border: 2px solid var(--v-primary-base);
                    .v-icon {
                        color : var(--v-primary-base);
                    }
                }
            }
            .v-stepper__step__step {
                background: white !important;
                border: 2px solid var(--v-mediumgrey-base);
                .v-icon {
                    color : grey;
                }
            }
        }
    }
}
    .banner {

        background: rgb(255,180,111);
        background: -moz-linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
        background: -webkit-linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
        background: linear-gradient(118deg, rgba(255,180,111,1) 0%, rgba(255,43,135,1) 56%, rgba(255,62,187,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb46f",endColorstr="#ff3ebb",GradientType=1);

        border-radius: 6px;

        width:100%;
        padding: 0px 40px;
        display:flex;
        align-items: stretch;

        .imgContent{
            display: flex;
            align-items: flex-end;
        }

        .content-text{
            color:#ffffff ;
            padding:35px;
            display:flex;
            flex-direction: column;
            justify-content: center;

            p{
                font-size:14px;
                line-height: 20px;
            }
            .bannerTitle{
                font-size:23px;
                font-weight: 800;
            }
        }
    }
    .v-stepper__step--inactive.completeStep {
        .v-stepper__step__step {
            background: rgba(91, 184, 93, 0.6) !important;
        }
    }

</style>
