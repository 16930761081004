<template>
    <div>
        <div v-for="wf, indexWf in workflowNodes" :key="'index_'+indexWf" class="wf">
            <h2>{{wf.name}}</h2>
            <div class="nodeContainer" v-show="dataLoaded">
                <div v-for="node, nodeIndex in wf.nodes" :key="'indexNode_'+nodeIndex" class="node">
                    {{node.name}}
                    <div :class="'create itemTemplateSelect '" v-if="node.config.template == ''" @click="openTemplateSelectDialog(node, indexWf, nodeIndex)">
                        <v-icon color="primary" large>$plus_l</v-icon>
                        <p>{{ $t("selectTemplate") }}</p>
                    </div>
                    <div v-else :class="'itemTemplateSelect ' + node.config.template.canal" @click="openTemplateSelectDialog(node, indexWf, nodeIndex)">
                        <div v-if="node.config.template.canal == 'EMAIL'">
                            <div class="itemPreview email" v-if="templates['template_wf_'+indexWf+'_node_'+nodeIndex] && templates['template_wf_'+indexWf+'_node_'+nodeIndex].hasOwnProperty('html')"><span class="template-span" v-html="templates['template_wf_'+indexWf+'_node_'+nodeIndex].html"></span> </div>
                            <div class="itemName"><b>#T{{ node.config.template.num }} - {{ node.config.template.version }} - {{ node.config.template.name }}</b></div>
                        </div>
                        <div v-if="node.config.template.canal == 'SMS' || node.config.template.canal == 'MMS' || node.config.template.canal == 'VMS' || node.config.template.canal == 'TEL'">
                            <div class="itemPreview"  v-if="templates['template_wf_'+indexWf+'_node_'+nodeIndex] && templates['template_wf_'+indexWf+'_node_'+nodeIndex].hasOwnProperty('text')"><span  class="template-span" v-html="templates['template_wf_'+indexWf+'_node_'+nodeIndex].text"></span></div>
                            <div class="itemName"><b>#T{{ node.config.template.num }} - {{ node.config.template.version }} - {{ node.config.template.name }}</b></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <WorkflowSelectTemplateComponent 
            @close="templateSelectDialog = false" 
            :type="dialogType" 
            :element="dialogElement" 
            :template="dialogTemplate" 
            :templateSelect="templateSelectDialog" 
            @selectTemplate="selectTemplate"/>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import WorkflowSelectTemplateComponent from '@/modules/workflow/components/WorkflowSelectTemplateComponent'
export default {
    name:'workflowStepComponent',
    props: ["operationId", "loadData"],
    components: {WorkflowSelectTemplateComponent},
    data() {
        return {
            workflowNodes: [],
            templates:{},
            templateSelectDialog: false,
            dialogType: "",
            dialogElement: {},
            dialogTemplate: {},
            wfIndex: "",
            nodeIndex: "",
            dataLoaded: false
        }
    },
     watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val && (this.dataLoaded == false)){
                    //get des workflows qui nécessite un template
                    GenericDataService.getData('/workflow/getWorkflowsWithTemplates?operation_id='+this.operationId).then((response) => {
                        this.workflowNodes = response.data.data;
                        //Pour chaque node de chaque workflow avec template
                        this.workflowNodes.forEach((wf, indexWf) => {
                            wf.nodes.forEach((node, nodeIndex) => {
                                //On récupère le template correspondant pour la thumb
                                if(node.config.template.hasOwnProperty('id')){
                                    GenericDataService.getData('/template/get?id='+node.config.template.id).then((response) => {
                                        this.templates['template_wf_'+indexWf+'_node_'+nodeIndex] = response.data.data.template
                                    })
                                }
                            })
                            if(indexWf == (this.workflowNodes.length - 1)){
                                setTimeout(() => {
                                   this.dataLoaded = true
                                }, 200);
                            }
                        })
                    })
                }
            }
        }
    },
    methods: {
        openTemplateSelectDialog(node, wfIndex, nodeIndex){
            this.dialogType = node.type == 'external_communication_email' ? 'EMAIL' : (node.type == 'external_communication_sms' ? 'SMS' : (node.type == 'external_communication_mms' ?  'MMS' : (node.type == 'external_communication_call' ? 'TEL' : 'VMS')))
            this.dialogElement = node
            this.dialogTemplate = node.config.template
            this.wfIndex = wfIndex
            this.nodeIndex = nodeIndex
            this.templateSelectDialog = true
        },
        selectTemplate(template){
            let templateToRecord = {
                id: template.id,
                canal: template.canal,
                name: template.name,
                num: template.num,
                version: template.version
            }
            
            this.templates['template_wf_'+this.wfIndex+'_node_'+this.nodeIndex] = template.template
            
            
            this.workflowNodes[this.wfIndex].nodes[this.nodeIndex].config.template = templateToRecord
            let workflow_id = this.workflowNodes[this.wfIndex].id
            let node_id = this.workflowNodes[this.wfIndex].nodes[this.nodeIndex].id

            GenericDataService.postData('/workflow/setTemplateConf', {workflow_id: workflow_id, node_id: node_id, templateConf:templateToRecord}).then((response) => {
                this.templateSelectDialog = false
            })

            
        },
        refreshTemplateWorkflows(){
            this.workflowNodes.forEach((wf, indexWf) => {
                wf.nodes.forEach((node, nodeIndex) => {
                    if(node.config.template.hasOwnProperty('id')) {
                        GenericDataService.getData('/template/get?id='+node.config.template.id).then((response) => {
                            this.templates['template_wf_'+indexWf+'_node_'+nodeIndex] = response.data.data.template
                        })
                    }
                })
            })
        }
    },
}
</script>
<style lang="scss">
    .nodeContainer {
        width:100%;
        display:flex;
        .node {
            width:24%;
            margin:10px;
            .itemTemplateSelect {
                position: relative;
                height: 200px;
                width: 100%;
                margin-right:1%;
                margin-bottom:15px;
                border: 1px solid rgba(0, 0, 0, 0.13);
                background-color: rgba(0, 0, 0, 0.03);
                cursor: pointer;
                //   &.EMAIL, &.MAILING, &.ENVELOPE {
                //     height:300px;
                //     .itemPreview {
                //       height: 269px;
                //     }
                //   }
                &.create {
                    border: 1px solid rgba(9, 87, 125, 0.2);
                    background-color: rgba(9, 87, 125, 0.1);
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    p {
                    margin-bottom:0;
                    text-transform: uppercase;
                    margin-top:10px;
                    font-weight:bold;
                    }
                }
                .itemPreview {
                    height: 169px ;
                    padding:30px;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    position:relative;
                    overflow: hidden;
                    overflow-y:auto;
                    &.email {
                    padding:0;
                    align-items: flex-start;
                    overflow:hidden;
                    embed {
                        overflow: hidden;
                    }
                    }
                    &.MMS {
                    padding:0px;
                    overflow:hidden;
                    img {
                        width:100%;
                        height:auto;
                    }
                    }
                    .template-span {
                        top: 0;
                        position: absolute;
                    }
                    
                }
                .itemName {
                        height:30px;
                        padding: 5px;
                        border-top: 1px solid rgba(0, 0, 0, 0.13);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
                        background-color: white ;
                    }
                }
        }   
    }
    
</style>