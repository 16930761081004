<template>
    <v-container fluid class="pa-0">
        <DatatableV2 :displayTitle="false" v-if="loadData" :flat="true" :url="apiUrl"/>
    </v-container>
</template>
<script>
import DatatableV2 from '@/components/DatatableV2'
export default {
    name:"ressourcesStepComponent",
    props:['operationId', 'loadData'],
    components: {DatatableV2}, 
    data() {
        return {
            //apiUrl:"/operation/getRessources",
            //apiUrl:"/entity/list",
        }
    },
    computed: {
        apiUrl(){
            return "/operation/"+this.operationId+"/getRessources"
        }
    },
}
</script>