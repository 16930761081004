<template>
    <v-dialog v-model="dialogEditorLP" persistent no-click-animation :retain-focus="false" fullscreen class="landingEditor">
        <v-card tile style="height:100%"  class="cardEditor">
            <v-toolbar flat dark class="toolbar bg-gradient">
                    <v-btn
                    depressed
                    color="white"
                    class="square mr-2"
                    text
                    @click.native="$emit('closeDialog')"
                >
                    <v-icon dark small>$prev</v-icon>
                </v-btn>
            
                <v-toolbar-title>Edition de la landing page</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn depressed outlined color="white" class="mr-3" v-if="this.entrypoint" @click="resetEntryPoint">
                    {{$t('resetLabel')}}
                </v-btn>
                <v-btn depressed outlined color="white" class="mr-3" @click="$emit('closeDialog')">
                    {{$t('cancelLabel')}}
                </v-btn>
                <v-btn depressed outlined @click="registerLanding" color="white">
                    {{$t('selectMediaLabel')}}
                </v-btn>
            </v-toolbar>
            <v-card-text class="editorContent pa-0" ref="editorContent">
                <htmlEditorComponent :dialog="dialogEditorLP" :currentLanding="currentLanding" :idLanding="idLanding" :css="css" ref="htmlEditor"/>
            </v-card-text>
        </v-card>
        <v-dialog width="600" v-model="confirmReset">
            <v-card>
                <v-toolbar tile flat dark class="bg-gradient">
                    <v-toolbar-title>{{$t('confirmResetLP')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined depressed small class="mr-2" @click="confirmReset = false">{{$t('cancelLabel')}}</v-btn>
                    <v-btn outlined depressed small @click="confirmResetEntrypoint ">{{$t('confirmLabel')}}</v-btn>
                </v-toolbar>
                <v-card-text class="pt-6 ">
                    {{$t('suretoResetLandingtoItModel')}}
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import htmlEditorComponent from '@/components/htmlEditorComponent'
export default {
    name:"landingPageEditor",
    props:['dialogEditorLP', 'css', 'currentLanding', 'idLanding', 'entrypoint'],
    components:{htmlEditorComponent},
    data() {
        return {
            publicPath: process.env.BASE_URL,
            editorHeight:0,
            confirmReset: false
        }
    },
    methods: {
        reload(){
            location.reload();
        },
        registerLanding(){

            let landingToSend = this._.cloneDeep(this.currentLanding);


            if(!this.entrypoint){
                GenericDataService.postData('/landingpage/setLandingSettings', {id: this.idLanding, settings: landingToSend, css: this.css}).then((response) => {
                    this.$emit('closeDialog')
                })
            } else {
                GenericDataService.postData('/landingpage/setLandingSettings', {id: this.idLanding, settings: landingToSend, css: this.css, entrypoint: this.entrypoint}).then((response) => {
                    this.$emit('closeDialog')
                })
            }
            
        },
        confirmResetEntrypoint(){
            GenericDataService.postData('/landingpage/resetEntryPointLanding', {entrypoint: this.entrypoint}).then((response) => {
                this.confirmReset = false
                this.$emit('reloadData', this.entrypoint.id)
                setTimeout(() => {
                    this.$refs.htmlEditor.refreshFrame(false, true)
                }, 200);
            })
        },
        resetEntryPoint(){
           this.confirmReset = true;
        },
        encodeHTML(string){
            return string.replace(/&/g, '&amp;')
               .replace(/</g, '&lt;')
               .replace(/>/g, '&gt;')
               .replace(/"/g, '&quot;')
               .replace(/'/g, '&apos;')
               .replace(/[\n\r]/g,' ');
        },
    },
    watch: {
        dialogEditorLP(val){
            if(val == true){
                setTimeout(() => {
                    this.$refs.htmlEditor.refreshFrame()
                }, 100);
                
            }
        }
    }
}
</script>
<style lang="scss">
    .landingEditor {
        height:100%;
        overflow:hidden;
        .toolbar {
            position:absolute;
            width:100%;
            display:flex;
            align-items: center;
        }
    }
  
    .editorContent {
        //padding-top:63px;
        position:relative;
        height: calc(100% - 63px);
        overflow-y:auto;
        width:100%;
    }
</style>