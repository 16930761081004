<template>
    <v-card rounded="lg" :class="!validationStep ? 'pa-3 pt-0' : 'pa-3 pr-0 pt-0 no-shadow' ">
        <v-card-title :class="validationStep ?  'pl-3 pb-4 pt-2' : 'px-3 pb-3 pt-3'">
            <h2 style="text-transform: none; font-size:22px;">{{$t('operationPlaces')}}</h2>

        </v-card-title>
        <v-card-text :class="validationStep ? 'pl-3 pr-0' : 'px-3'">
            <placesStepForm class="px-0" :loadData="true" :stepper="false" :validationStep="validationStep" :operationId="operationId" />
        </v-card-text>
    </v-card>
</template>
<script>
import placesStepForm from '@/modules/operations/operationsComponents/placesStepForm'
import PlacesFormDialogComponent from '@/components/dialogs/PlacesFormDialogComponent'
export default {
    name: "placesOperationComponent",
    components: {PlacesFormDialogComponent, placesStepForm},
    props: ["values", "operationId", "validationStep"],
    data() {
        return {
            dialog:false,
            edit: false,
            placeTo: {
                id:0,
                label: "",
                adress: "",
                zip:"",
                city: ""
            },
        }
    },
    methods: {
        editPlace(place){
            this.placeTo = this._.cloneDeep(place)
            this.edit = true,
            this.$forceUpdate()
            this.dialog = true
        },
        removePlace(place){
            this.$emit('removePlace')
        },
        cancelDialog() {
            this.placeTo.id = 0,
            this.placeTo.label = "",
            this.placeTo.adress = "",
            this.placeTo.zip = "",
            this.placeTo.city = "",
            this.dialog = false
        },
        updatePlace(place) {
            this.$emit('updatePlace', place)
        },
        recordPlace(place){
            this.$emit('recordPlace', place)
        }
    },
}
</script>
<style lang="scss">

    .place {
        position:relative;
        .icon {
            position:absolute;
            right:0;
            top:50%;
            transform: translateY(-50%)
        }
    }
</style>
