<template>
    <v-dialog v-model="dialog" persistent width="600">
        <v-card>
            <v-toolbar tile flat dark :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
                <v-toolbar-title v-if="!edit">{{$t(dialogTitle)}}</v-toolbar-title>
                 <v-toolbar-title v-else>{{$t(dialogEditTitle)}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn outlined dark depressed @click.native="cancelDialog" class="ml-3">ANNULER</v-btn>
                <v-btn v-if="!edit" outlined dark depressed @click="recordPlace" class="ml-3">AJOUTER</v-btn>
                <v-btn v-else outlined dark depressed @click="updatePlace" class="ml-3">METTRE à JOUR</v-btn>
            </v-toolbar>
            <v-card-text class="mt-6">
                <v-form v-model="valid" ref="placeForm">
                    <v-text-field dense filled v-model="value.label" :rules="[v => (v != '') ? true : $t('placeNameError')]" required :label="$t('placeNameLabel')" class="required"></v-text-field>
                    
                    <Editor
                        api-key="u1m5pl31db4x4he8xgf2u3vna13kj2x9n2m24tix1jhl2hi0"
                        v-model="value.adress"
                        class="mb-6"
                        :init="{
                            selector: 'textarea#custom-menu-item',
                            placeholder: $t('adressLabel'),
                            contextmenu: false,
                            height: 200,
                            toolbar: false,
                            forced_root_block : false,
                            menubar: false,
                            menu:false,
                        }"
                    />
                    <v-text-field dense filled :rules="[v => (this.value.zip != '') ? true : $t('zipCodeError')]" v-model="value.zip" required :label="$t('zipCodeLabel')" class="required mt-6"></v-text-field>
                    <v-text-field dense filled :rules="[v => (v != '') ? true : $t('cityError')]" v-model="value.city" required :label="$t('cityLabel')" class="required"></v-text-field>
                    
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
export default {
    name:"PlacesFormDialogComponent",
    props:['value', 'dialog' , 'edit'],
    components: {Editor},
    data() {
        return {
            dialogTitle: 'PlaceAddForm',
            dialogEditTitle: "PlaceEditForm",
            publicPath: process.env.BASE_URL,
            valid:false,
        }
    },
    
    methods: {
        cancelDialog() {
            this.$emit('closeDialog')
        },
        recordPlace() {
            this.$emit('recordPlace', this.value)
        },
        updatePlace() {
            this.$emit('updatePlace', this.value)
        }
    },
    watch: {
        dialog(newVal){
            this.$forceUpdate()
        }
    }
}
</script>