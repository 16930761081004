<template>
    <span>
        <v-btn solo depressed :disabled="disable" @click="handleClick" color="primary" class="btn-reaffect mb-4 ml-3">
            {{ $t('reaffectVendors') }}
        </v-btn>
        <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'"
            />

        <v-dialog v-model="entitySelectDialog" width="600">
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                    <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                        <div class="d-flex align-center">
                            <v-toolbar-title class="d-flex align-center">{{$t("selectEntityForReaffectation")}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="entitySelectDialog = false">{{  $t('cancel') }}</v-btn>
                            <v-btn outlined dark depressed @click="reaffectVendors" class="ml-3">{{ $t('reaffect') }}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text>
                    <div class="mt-3">{{$t('whichEntity')}}</div>
                    <v-select hide-details required solo flat class="input-form" v-model="entitySelected" :items="entities" ></v-select>
                    <p class="error--text">{{ errorEntity }}</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>
import getForm from "@/mixins/mixins";
export default {
    name: 'BtnReaffectComponent',
    components: {
        FormDialogComponent: () => import("@/components/dialogs/FormDialogComponent"),
    },
    mixins: [getForm],
    data() {
        return {
            form: null,
            entitySelectDialog: false,
            entitySelected : null,
            errorEntity: ""
        }
    },
    props: {
        entities: {
            type: Array
        },
        operationId: {
            type: String
        },
        disable: {
            type: Boolean
        }
    },
    methods: {
        reaffectVendors() { 
            if(!this.entitySelected){
                this.errorEntity = this.$t('selectEntityForReaffectation')
                return;
            } else {
                let url = '/contact/getReassignContactsForm?operation_id=' + this.operationId + '&entity_id='+this.entitySelected;
                this.getForm(url, false, false, null, null, true, true);
                this.errorEntity = ''
                this.entitySelectDialog = false
            }
            console.log(this.entitySelected)
        },
        handleClick() {
           if(this.entities && this.entities.length == 1){
                let url = '/contact/getReassignContactsForm?operation_id=' + this.operationId + '&entity_id='+this.entities[0].value;
                this.getForm(url, false, false, null, null, true, true);
           } else {
                //mutli entité : afficher popup
                this.entitySelectDialog = true;
           }
        }
    }
}
</script>

<style scoped>
.btn-reaffect {
    /* Add your button styles here */
}
</style>