<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h5>{{ $t('marketingCampaign') }}</h5>
            </v-col>
            <template v-for="(rp, index) in retroplanning" >
                <v-col cols="4">
                    <VCustomLibraryPicker :fromStepper="true" :model="{ 'operation_id': operationId, 'retroplanning_id': rp.id, 'rp': rp }" fieldName="rp" :field="{ 'libType': rp.canal, 'campaign': rp, 'inCampaign': true }" :returnEmit="true" @refreshAllTemplates="refreshData" @selectModel="selectModel($event, 'retroplanning', rp.canal, index, rp.id)" :propertyToCheck="'template_id'" :key="'rppicker_'+rp.id+'_'+rpKey" />
                </v-col>
            </template>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h5>{{ $t('workflowTemplate') }}</h5>
            </v-col>
            <v-col cols="4" v-for="(wf, index) in workflows" :key="'wf_' + index">
                <VCustomLibraryPicker :fromStepper="true" :model="{ 'operation_id': operationId, 'wf': wf }" fieldName="wf" :field="{ 'libType': wf.canal, 'campaign': wf, 'inCampaign': true }" :returnEmit="true" @refreshAllTemplates="refreshData" @selectModel="selectModel($event, 'workflows', wf.canal, index, null, wf.id, wf.node_id)" :propertyToCheck="'template_id'" :key="'wfpicker_'+wf.id+'_'+rpKey"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import GenericDataService from '@/services/GenericDataService';
import VCustomLibraryPicker from '../../../components/forms/inputs/v-custom-library-picker.vue';

export default {
    name: "templatesStepComponent",
    components: { VCustomLibraryPicker },
    props: ["operationId", "loadData"],
    data() {
        return {
            retroplanning: [],
            workflows: [],
            rpKey:0
        }
    },
    watch: {
        loadData: {
            immediate: true,
            handler(val) {
                if(val){
                    GenericDataService.getData('/operation/' + this.operationId + '/getTemplatesStep').then((response) => {
                        this.retroplanning = response.data.data.retroplanning;
                        this.workflows = response.data.data.workflows;
                    })
                    this.$emit('refreshRetroplanningAndOpe')
                }
            }
        },
    },
    beforeDestroy() {
    },
    methods: {
        refreshData(){
            GenericDataService.getData('/operation/' + this.operationId + '/getTemplatesStep').then((response) => {
                this.retroplanning = response.data.data.retroplanning;
                this.workflows = response.data.data.workflows;
                this.rpKey++
            })
            this.$emit('refreshRetroplanningAndOpe')
        },
        selectModel(model, type, canal, index, retroplanning_id = null, workflow_id = null, workflow_node_id = null) {
            let url = '/operation/' + this.operationId + '/setTemplate?type=' + type + '&canal=' + canal + '&template_id=' + model.id;

            if(type === 'retroplanning')
                url += '&retroplanning_id=' + retroplanning_id;

            if(type === 'workflows')
                url += '&workflow_id=' + workflow_id + '&workflow_node_id=' + workflow_node_id;

            GenericDataService.getData(url).then((response) => {
                this.retroplanning = response.data.data.retroplanning;
                this.workflows = response.data.data.workflows;
                this.$emit('refreshRetroplanningAndOpe')
            })
        }
    },
}
</script>

<style lang="scss">
</style>